<template>
    <div>
        <Header></Header>
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-lg-2 text-center">

                <h1>Über uns</h1>
                <h2>Autos sind unsere Leidenschaft</h2>
            </div>
            <div class="col-lg-8 offset-lg-2">
                <p class="demi">
                    Die Waschprofis aus Dresden sind Ihr kompetenter Partner, wenn es um die professionelle Reinigung und Aufbereitung Ihres Fahrzeugs geht. Als langjähriger Dienstleister im Automobilhandel wissen wir um die Belange unserer Kunden und natürlich auch um den ersten optischen Eindruck, den ein Wagen innerhalb weniger Sekunden hinterlässt.<br>
                    <br>
                    Wir bieten Ihnen ein umfangreiches Leistungspaket zur optischen und hygienischen Aufwertung Ihres Fahrzeugs. Erhalten Sie kompetente Beratung sowie Qualitätsarbeit vom Fachmann und bleiben Sie mit unserem Mietwagen-Service uneingeschränkt mobil und flexibel.
                </p>
                </div>
            </div>
        </div>
        <div class="red-bg">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-7 offset-lg-1 col-xl-4 offset-xl-2">
                        <h3>Werde Waschprofi!</h3>
                    </div>
                    <div class="col-md-4 col-xl-3">
                        <router-link to="/offer" class="button white">
                            Stellenangebote
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>

    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";

    export default {
        name: 'About',
        components: {
            Header,
            Footer,
        },
    }
</script>
<style lang="scss" scoped>
    h1{
        font-weight:400;
        font-size: 21px;
        letter-spacing: 2.1px;
        text-transform: uppercase;
        margin-bottom: 30px;
    }

    h2{
        font-family: 'futura-pt-bold';
        font-size: 60px;
        letter-spacing: 1.5px;
        margin-bottom: 50px;
    }

    p{
        font-size: 22px;
        letter-spacing: 0.55px;
    }

    .red-bg{
        margin-top:110px;
        padding-top:90px;
        padding-bottom:90px;

        h3{
            font-family: 'futura-pt-bold';
            font-size:40px;
            letter-spacing: 1px;
        }
    }


</styles>